import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, NgFor } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { PbbSvgIconComponent } from '../pbb-svg-icon/pbb-svg-icon.component';

@Component({
  selector: 'pbb-info-list',
  templateUrl: './info-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatListModule, NgFor, MatIconModule, TranslateModule, PbbSvgIconComponent, CommonModule],
})
export class InfoListComponent {
  @Input() list: any[] = [];
}
