<mat-list class="info-list">
  <ng-container *ngFor="let item of list; let i = index">
    <mat-list-item class="flex flex-row flex-full-width flex-start" id="info-list-item-{{ i + 1 }}">
      <mat-icon
        *ngIf="item.icon && !item.svgIcon"
        class="icon-mdl mr-16 flex-align-start"
        id="info-list-item-{{ i + 1 }}-icon"
        >{{ item.icon }}</mat-icon
      >
      <span *ngIf="item.svgIcon && !item.icon" class="mr-16 flex-align-start">
        <pbb-svg-icon
          class="icon-mdl"
          [icon]="item.svgIcon"
          class="wedauthn-icon"
          id="info-list-item-{{ i + 1 }}-svg-icon"
        ></pbb-svg-icon>
      </span>

      <div class="flex flex-col flex-full-width flex-start">
        <span class="mat-body-1 mb-8 text-dark" id="info-list-item-{{ i + 1 }}-title">{{
          item.title | translate
        }}</span>
        <span class="mat-body-2 text-grey-subtitle prewrap" id="info-list-item-{{ i + 1 }}-subtitle">{{
          item.subtitle | translate
        }}</span>
      </div>
    </mat-list-item>
  </ng-container>
</mat-list>
